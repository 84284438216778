.headerArea {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1049;

    .headerBottomArea {
        background: #141412;
        flex: center;

        .logo {
            padding: 10px 0;

            img {
                height: auto;
                max-height: 70px;
                width: auto;
            }
        }

        .mainMenuWrap {
            text-align: right;
            color: #fff;
            cursor: pointer;
            display: block;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0;
            padding: 35px 0;
            text-transform: uppercase;

            li {
                display: inline-block;
                position: relative;
                padding: 0px 10px;
                color: #fff;

                a {
                    color: #fff;
                }

                &:hover,
                &.active {
                    a {
                        color: #eeba2b;
                    }
                }

                &:hover {
                    .megaMenuContent {
                        background: #fff;
                        display: block; // Show mega menu content on hover
                        position: absolute;
                        left: 0;
                        top: 100%; // Adjust as necessary to fit your layout
                        width: auto; // Set to auto or 100% depending on desired width
                        z-index: 999;
                        box-shadow: 0 2px 5px rgba(0,0,0,.05);
                        justify-content: space-around; // Distribute content blocks
                        text-align: left;
                        text-transform: capitalize;
                        transform: scaleY(1);
                        display: grid;
                        grid-template-columns: repeat(2, 1fr) 2fr; // 3 equal columns
                        grid-gap: 20px; // Space between the grid items   
                        padding: 20px;
                    }
                }

                .tax-law-item {
                    display: block; // Ensure it’s treated as a block element, stacking below Family Law
                    margin-left: 0; // Reset any margin that might cause it to offset to the right
                }

                .megaMenuContent {
                    display: none; // Hide by default
                    transform: scaleY(0); // Start scaled down
                    transform-origin: top;
                    transition: transform 0.3s ease; // Animate the transformation

                    .contentBlock {
                        min-width: 200px; // Example min-width, adjust as needed

                        h4 {
                            color: #eeba2b; // Heading color
                            padding-bottom: 10px; // Spacing below the heading
                            font-size: 20px;
                            text-align: center;
                        }

                        ul {
                            list-style: none; // Remove list styles
                            padding: 0; // Remove padding
                            margin: 0; // Remove margins
                            font-size: 18px;

                            li {
                                &.tax-law-item {
                                    display: block;
                                    margin-left: 0;
                                    width: 100%;
                                    position: relative;
                                    clear: both;
                            }
                                a {
                                    color: #000000;
                                    display: block; // Make the link take up the whole list item
                                    padding: 0.5rem 0; // Space above and below the link

                                    &:hover,
                                    &.active {
                                        color: #eeba2b; // Highlight color on hover/active
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // Hide mobile attorneys item by default
            .mobile-attorneys-menu-item {
                display: none;
            }

            // Show desktop attorneys submenu item by default
            .desktop-attorneys-menu-item {
                display: block;
            }
        }

        .responsiveWrapper {
            display: none;

            @media (max-width: 992px) { // Adjust based on your breakpoint for mobile devices
                display: block;

                .mainMenuWrap {
                    display: none;
                }

                &.active {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    background-color: rgba(0, 0, 0, 1); // Semi-transparent background
                    z-index: 1050; // Higher than other elements to cover the page
                    display: flex;
                    flex-direction: column;
                    justify-content: center; // Centers the menu items vertically
                    align-items: center; // Centers the menu items horizontally

                    .mainMenuWrap {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;

                        li {
                            display: block; // Stack menu items vertically
                            width: 100%;
                            text-align: center; // Center-align the text
                            padding: 15px 0; // Add padding for touch targets
                            
                            a {
                                font-size: 1.5rem; // Increase font size for better readability
                                color: #ffffff; // Light color for visibility against the dark background
                                padding: 10px 20px;
                            }
                        }

                        // Show mobile attorneys menu item in responsive mode
                        .mobile-attorneys-menu-item {
                            display: block;
                        }

                        // Hide desktop attorneys submenu item in responsive mode
                        .desktop-attorneys-menu-item {
                            display: none;
                        }
                    }
                }
            }
        }

        .responsiveTrigger {
            display: none;

            @media (max-width: 996px) { 
                display: block;
                right: 20px;
                top: 20px;
            }

            cursor: pointer;
            margin-top: 20px;
            position: fixed;
            right: 20px;
            z-index: 1051;

            a, span {
                display: block;
                width: 30px;
                height: 3px;
                background-color: #eeba2b;
                margin: 5px 0; // Reduce space for a compact design

                &:nth-child(2) {
                    margin-top: 4px; 
                    margin-bottom: 4px;
                }

                transition: all 0.3s ease;
            }

            &.active span {
                // Transform hamburger into cross
                &:nth-child(1) {
                    transform: translateY(11px) rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: translateY(-11px) rotate(-45deg);
                }
            }
        }

        .btnStyle {
            margin-top: 20px;

            a {
                background: transparent;
                font-size: 14px;
                font-weight: 700;
                color: #fff;
                padding: 9px 15px;
                border: 2px solid #eeba2b;
                border-radius: 47px;
                text-transform: capitalize;
                white-space: nowrap;
                display: flex;
                justify-content: center;
                transition: background-color 0.3s ease; 

                &:hover {
                    background: #eeba2b;
                }
            }

            @media (max-width: 996px) {
                display: none;
            }
        }
    }
}

@media (max-width: 992px) { 
    .logo {
        display: block;
    }
}
