.immigrationServiceArea {
  background-color: #f9f9f9;
  padding: 2rem 0;

  .title {
    font-size: 2rem;
    color: #333;
    text-align: center;
  }

  .tagline {
    color: #666;
    text-align: center;
    margin-bottom: 2rem;
  }

  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px;

    @media (max-width: 1200px) {
      padding: 0 10%;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      padding: 0 5%;
    }

    @media (max-width: 375px) {
      padding: 0 2%;
    }
  }

  .category-title {
    font-size: 1.5rem;
    color: #333;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    text-align: center;
    width: 100%;
    
    @media (max-width: 768px) {
      padding: 20px;
    }
  }

  .service-list {
    padding: 40px;
    text-align: center; // Center align the list items if that's the intended design
    margin: auto; // Center the .service-list itself
    max-width: 80%; // You can adjust this as needed

    li {
      padding: 0.25rem 0;
      color: #333;
      display: inline-block; // Make list items inline-block if you're centering text within .service-list
      width: 100%; // Make list items full width if .service-list is text-align: center

      @media (max-width: 768px) {
        padding: 0.15rem 0;
      }
    }
  }

  button {
    display: block;
    width: auto;
    padding: 1rem;
    background-color: #141414;
    color: #fff;
    text-transform: uppercase;
    border: none;
    margin-top: 2rem;
    margin: auto;
    &:hover {
      background-color: #eeba2b;
    }
    @media (max-width: 768px) {
      padding: 0.75rem;
    }
  }
}
