	/*
  	Flaticon icon font: Flaticon
  	Creation date: 11/10/2018 18:34
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon.eot");
  src: url("../fonts/flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon.woff") format("woff"),
       url("../fonts/flaticon.ttf") format("truetype"),
       url("../fonts/flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
        font-size: 60px;
        font-style: normal;
}


.flaticon-network:before { content: "\f100"; }
.flaticon-scale:before { content: "\f101"; }
.flaticon-lawyer:before { content: "\f102"; }
.flaticon-parents:before { content: "\f103"; }
.flaticon-wounded:before { content: "\f104"; }
.flaticon-employee:before { content: "\f105"; }
.flaticon-thief:before { content: "\f106"; }
.flaticon-university-graduate-hat:before { content: "\f107"; }
.flaticon-house:before { content: "\f108"; }
.flaticon-book:before { content: "\f117"; }
/* .flaticon-technology:before { content: "\f109"; } */