.singleContentArea {
    .singleContentImg {
      img {
        width: 100%;
      }
    }
    .singleContentWrap {
      h3 {
        font-size: 36px;
        margin-bottom: 25px;
      }
  
      h5 {
        font-size: 22px;
        font-family: 'Open Sans', sans-serif;
        line-height: 40px;
        margin-bottom: 30px;
      }
  
      p {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 35px;
      }
  
      .avatarWra {
        margin-top: 15px;
  
        img {
          width: 100%;
        }
  
        h4 {
          font-size: 24px;
          margin-bottom: 20px;
        }
  
        ul {
          li {
            font-size: 15px;
            margin-bottom: 10px;
  
            &::before {
              content: "\f046";
              margin-right: 15px;
              font-family: 'fontawesome';
              color: #c0b596;
            }
  
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 576px) {
    .singleContentArea {
      .singleContentWrap {
        h5 {
          line-height: 30px;
          font-size: 18px;
        }
  
        p {
          font-size: 14px;
        }
  
        .avatarWra {
          img {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
.pipedriveWebForms {
    margin-left: -120px;
    padding: 10px;
    
    @media (min-width: 990px) and (max-width: 1200px) {
      margin-left: -64px;
    }
  
    @media (max-width: 768px) {
      margin: 0;
    }
}