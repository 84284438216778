.headerArea{
    .headerTop {
        .headerTopMainWrapper{
            border-bottom: 1px solid rgba(255,255,255,.07);
            padding: 10px 0;
            display: flex; // Use flexbox to allow flexible layouts
            justify-content: space-between; // Distributes space between elements
            align-items: center; // Aligns items vertically
        }
        background: #141412;
        ul{
            text-align: right;
            @media (max-width:576px) {
                text-align: center;
            }
            li{
                display: inline-block;
                padding: 15px;
                color: #fff;
                font-size: 15px;
                color: #fff;
                &:first-child{
                    position: relative;
                    padding-left: 0;
                    &::before{
                        position: absolute;
                        content: "";
                        right: 0;
                        top: 14px;
                        width: 1px;
                        height: 25px;
                        background: rgba(255,255,255,.07);
                    }
                    
                }
                &:last-child{
                    padding-right: 0;
                }
                i{
                    padding-right: 0px;
                    color: #eeba2b;
                    
                }
            }
            &.accountLoginArea{
                @media (max-width:576px) {
                    justify-content: center!important;
                }
                li{
                    padding-left: 0;
                    @media (max-width:768px) {
                       padding: 0;
                    }
                    &::before{
                        display: none;
                    }
                }
                
            }
        }
        .btnStyle {
            @media (max-width:576px) {
                text-align: center!important;
            }
        }
        // .btnStyle{
        //     a{
        //         background: transparent;
        //         font-size: 14px;
        //         font-weight: 700;
        //         color: #fff;
        //         padding: 9px 15px;
        //         border: 2px solid #eeba2b;
        //         border-radius: 47px;
        //         text-transform: capitalize;
        //         white-space: nowrap;
        //         display: flex; // Use flex to center content and manage spacing
        //         align-items: center; // Center align the items vertically
        //         justify-content: center; // Center content horizontally
        //         margin-right: 20px; // Add margin to the right for spacing between buttons
        //         &:last-child {
        //             margin-right: 10px; // Remove margin for the last button
        //         }
        //         @media (max-width:1024px) {
        //             font-size: 12px;
        //             padding: 9px;
        //         }
        //         @media (max-width: 576px) {
        //             width: 100%; // Full width buttons on small screens
        //             padding: 12px 10px; // Adjust padding for smaller screen size
        //             margin: 5px 0 0 20px; // Add vertical margin for separation
        //         }
        //     }
        // }
    }
    &.headerAreaStyleThree{
        .headerTop{
            background: transparent;
            padding: 0;
            @media (max-width:768px) {
                background: #151a30;
            }
            .headerTopMainWrapper{
                padding: 10px 15px;
                background: #151a30;
                @media (max-width:768px) {
                    padding: 10px 0;
                }
                .btnStyle {
                    a{
                        padding: 9px 13px;
                    }
                }
            }
        }
    }
}