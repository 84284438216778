.counterArea{
    padding: 100px 0px;
    background: url(../../images/counter/la-skyline.jpeg)  center center/cover no-repeat local;
    .counterTextWrapper {
        padding: 20px;
        margin-left: 60px;
        h2 {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 1rem;
            color: white;
        }
        p {
            font-size: 1rem;
            line-height: 1.5;
            color: white;
        }
        @media (max-width: 992px) {
            order: -1;
            padding-bottom: 2rem;
        }
    }
    .counterMainWrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .counterWrap{
            flex: 0 0 49.5%;
            width: 49.5%;
            background: rgba(192, 181, 150, .2);
            margin-bottom: 5px;
            min-height: 170px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            .count{
                font-size: 50px;
                font-weight: bold;
                color: #fff;
                margin: 0;
                line-height: 10px;
                font-family: 'Playfair Display', serif;
                @media (max-width:375px) {
                    font-size: 30px;
                }
                span{
                    &.level{
                        font-weight: 300;
                    }
                }
            }
            p{
                font-size: 18px;
                font-weight: normal;
                color: #eeba2B;
                margin-bottom: 0;
                padding-top: 20px;
                @media (max-width:375px) {
                    font-size: 15px;
                }
            }
        }
    }
}