.contactTeamAreaBackground {
    background-color: #f5f5f5; // Replace with your desired color
  }
  .contactTeamContent{
    position: relative;
    margin-top: -40px;
    background: #ededed;
    transform: translateY(40px);
    transition: all .3s;
    text-align: center;
    border: 1px solid #f5f5f5;
    h3{
        padding: 30px 0 5px;
        font-size: 24px;
    }
    span{
        color: #F8CE5A;
    }
    ul{
        display: flex;
        justify-content: center;
        margin: 20px 0;
        li{
            padding: 0 10px;
            a{
                color: #eeba2b;
            }
        }
    }
}

.contactusPageArea{
    padding: 100px 0;
    .contactUsInfo{
        h3{
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 30px;
        }
        h4{
            font-size: 24px;
            color: #222;
            margin-bottom: 10px;
            margin-top: 20px;
        }
        span{
            display: block;
        }
    }
    .contactUSForm{
        h3{
            margin-bottom: 40px;
            font-size: 30px;
        }
        .contactForm{
            .formInput{
                input,
                select,
                textarea{
                    background: #ebebeb;
                    border: none;
                    color: #495057;
                }
            }
        }
    }
}