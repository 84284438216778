.bannerWrapper{
    height: 320px;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-bottom: 60px;
    &::before{
        position: absolute;
        content: "";
        background: rgba(0, 0, 0, .65);
        bottom: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
    }
    span{ 
        color: gold;
    }
    h3{
        font-size: 36px;
        color: #fff;
        line-height: 50px;
        padding-bottom: 25px;
        margin-bottom: 0;
        @media (max-width:1024px) {
            font-size: 24px;
            line-height: 20px;
        }
        @media (max-width:576px) {
            font-size: 24px;
            line-height: 40px;
        }
    }
    h5{
        font-size: 20px;
        color: #fff;
        line-height: 20px;
        padding-bottom: 25px;
        margin-bottom: 0;
        @media (max-width:1024px) {
            font-size: 24px;
            line-height: 20px;
        }
        @media (max-width:576px) {
            font-size: 24px;
            line-height: 40px;
        }
        span{
            font-size: 28px;
        }
    }
    br {
        margin-bottom: 10px;
        padding-top: 20px;
      }    
    aside {
        margin-bottom: 200px;
    }
    .btnStyle4{
        a,
        button{
            background-color: #141412;
            font-size: 16px;
            font-weight: 600;
            color: #EEBA2B;
            padding: 10px 10px;
            border: 2px solid transparent;
            text-transform: capitalize;
            display: inline-block;
            margin-top: 3px;
            @media (max-width:1200px) {
                font-size: 14px;
                padding: 10px 35px;
            }
            @media (max-width:768px) {
                padding: 10px 13px;
            }
            &:hover{
                background: rgba(238, 186, 43, 0.8);
                color: #141412;
            }
        }
    }
}

@media (max-width: 990px) {
    .bannerWrapper { 
      background-image: none !important;
    }
  }