.singleArea{
    padding: 50px 0px;
    .pr25{
        padding-right: 25px;
        @media (max-width:768px) {
            padding-right: 0;
        }
    }
    .pl25{
        padding-left: 25px;
        @media (max-width:768px) {
            padding-left: 0;
        }
    }
    
}

@media (max-width: 990px) {
    .singleArea .container .row {
      display: flex;
      flex-direction: column;
      margin-top: -20px;
    }
  
    .singleArea .container .row .col-lg-4 {
      order: 2; // Moves the sidebar to the bottom on mobile
    }
  
    .singleArea .container .row .col-lg-8 {
      order: 1; // Ensures content area is above the sidebar on mobile
    }
  }