.serviceArea{
    padding: 0 0 20px 180px;
    background: #f1f1f1;
    margin-top: -10px;
    margin-bottom: -10px;
    position: relative;
    @media (max-width:480px) {
        padding: 20px 0 0 0;
    }
    .brand-promises-heading {
        text-align: center;
        padding-top: 20px;
        margin: 0 180px 20px 0;
        font-size: 28px;
    }
    .serviceItem{
        overflow: visible;
        margin: 0 0 30px 0;
        background: #fff;
        @media (max-width:480px) {
            margin: 10px 0 10px 0; 
            margin-left: 60px;   
        }
        .serviceIcon{
            background: #141412;
            flex-grow: 0;
            flex-shrink: 0;
            text-align: center;
            @media (max-width:1200px) {
                height: 90px;
                width: 90px;
            }
            i{
                color: #EEBA2B;
            }
        }
        .serviceText{
            padding: 16px 25px;
            flex-grow: 1;
            span{
                color: #333;
                font-size: 16px;
                white-space: nowrap;
            }
            h3{
                font-size: 20px;
                font-weight: bold;
                color: #333;
                text-align: center;
                white-space: nowrap;
                @media (max-width:1200px) {
                    font-size: 22px;
                }                        
            }
        }
    }
    &.serviceAreaStyleTwo{
        background: url(../../images/services/1.jpg)no-repeat center center / cover;
        position: relative;
        z-index: 1;
        &::before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(21, 26, 48, .66);
            z-index: -9;
        }
        .serviceItem{
            background: transparent;
            border: 1px solid rgba(192, 181, 150, .4);
            .serviceIcon{
                background: transparent;
                border-right:  1px solid rgba(192, 181, 150, .4);
                i{
                    color: #c0b596;
                }
            }
            .serviceText{
                h3{
                    color: #fff;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .serviceArea{
        display: none;
    }
}