.teamArea{
    padding: 100px 0px;
    .teamWrapper{
        overflow: hidden;
        box-shadow: 1px 1px 5px rgba(0, 0, 5, .08);
        position: relative;        
        .teamImage{
            border: 1px solid #fff;
            border-bottom: none;
        }
        .teamContent{
            position: relative;
            margin-top: -40px;
            background: #f5f5f5;
            transform: translateY(40px);
            transition: all .3s;
            text-align: center;
            border: 1px solid #f5f5f5;
            h3{
                padding: 30px 0 5px;
                font-size: 24px;
                a {
                    color: black; // Original color
    
                    &:hover {
                        color: #eeba2b; // Hover color
                    }
    
                    text-decoration: none; // Optional: remove underline from the link
                }
            }
            span{
                color: #F8CE5A;
            }
            ul{
                display: flex;
                justify-content: center;
                margin: 20px 0;
                li{
                    padding: 0 10px;
                    a{
                        color: #eeba2b;
                    }
                }
            }
        }
        &:hover{
            .teamContent{
                transform: translateY(0px);
                background: #fff;
            }
        }
    }
    .slick-prev,
    .slick-next {
        width: 50px;
        height: 50px;
        background: rgba(192, 181, 150, .3);
        transition: all .3s;
        border-radius: 5px;
        z-index: 10;
        cursor: pointer;
        @media (max-width:375px) {
            height: 35px;
            width: 35px;
        }
        @media (max-width:1200px) {
            background: rgba(238, 186, 43);
        }
        &:hover{
            background: rgba(238, 186, 43, 0.7);
            &::before{
                color: #333;
            }
        }
    }
    .slick-prev {
        left: -70px;
        @media (max-width:1200px) {
            left: -30px;
        }
        @media (max-width:600px) {
            left: -10px;
        }
        &::before {
            font-family: "FontAwesome";
            content: "\f104";
            opacity: 1;
            color: #333;
        }
    }
    .slick-next {
        right: -70px;
        @media (max-width:1200px) {
            right: -30px;
        }
        @media (max-width:600px) {
            right: -10px;
        }
        &::before {
            font-family: "FontAwesome";
            content: "\f105";
            opacity: 1;
            color: #333;
        }
    }
}