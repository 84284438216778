.singleContentArea {
    .visaLinks {
        ul {
            list-style-type: disc; // Ensure default bullet points
            padding-left: 20px; // Adjust padding to align bullets

            li {
                margin-left: 0; // Reset margin if customized
                padding-left: 0; // Reset padding if customized
                position: relative; // For custom bullets if added later
                
                // Remove any custom ::before content
                &::before {
                    content: none;
                }

                a { // This will target the rendered <a> tags from <Link>
                    color: #0000ff; // Set the color of links to blue

                    // Optional: Add styles for hover state
                    &:hover {
                        color: darken(#0000ff, 10%); // Darker shade of blue on hover
                    }
                }
            }
        }
    }

    .singleContentImg {
        img {
            width: 100%;
        }
    }

    .singleContentWrap {
        h3 {
            font-size: 36px;
            margin-bottom: 25px;
        }

        h5 {
            font-size: 22px;
            font-family: 'Open Sans', sans-serif;
            line-height: 40px;
            margin-bottom: 30px;

            @media (max-width: 576px) {
                line-height: 30px;
                font-size: 18px;
            }
        }

        p {
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 35px;

            @media (max-width: 576px) {
                font-size: 14px;
            }
        }

        .avatarWra {
            margin-top: 15px;

            img {
                width: 100%;

                @media (max-width: 576px) {
                    margin-bottom: 30px;
                }
            }

            h4 {
                font-size: 24px;
                margin-bottom: 20px;
            }

            ul {
                list-style-type: disc; // Ensure default bullet points
                padding-left: 20px; // Adjust padding to align bullets

                li {
                    font-size: 15px;
                    margin-bottom: 10px;

                    &::before {
                        content: none; // Remove any custom ::before content
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.pipedriveWebForms {
    margin-left: -120px;
    padding: 10px;

    @media (min-width: 990px) and (max-width: 1200px) {
        margin-left: -64px;
    }

    @media (max-width: 768px) {
        margin: 0;
    }
}
