.contactArea {
    background: url(../../images/contact/1.jpg) center center/cover no-repeat local;
    position: relative;
    padding: 20px;
    &::before {
        content: "";
        background-color: rgba(20, 20, 20, 0.18);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }   
    .contactInfo{
        @media (max-width:768px) {
            margin-bottom: 40px;
        }
        span{
            font-size: 16px;
            color: #facf58;
        }
        h2{
            font-size: 36px;
            margin-bottom: 20px;
            margin-top: 10px;
            color: #ffffff;
            position: relative;
            display: inline-block;
            &::before{
                content: "";
                position: absolute;
                right: -68px;
                top: 25px;
                width: 60px;
                height: 1px;
                background: #facf58;                
                @media (max-width:1200px) {
                    width: 30px;
                    left: -35px;
                }
            }
        }
        h6{
            font-size: 18px;
            color: #facf58;
            font-family: 'Open Sans', sans-serif;
            @media (max-width:1200px) {
                font-size: 16px;
            }
        }
        p{
            margin-top: 30px;
            color: #eeeeee;
            line-height: 30px;
            // text-overflow: clip;
        }
        ol{
            margin-top: 30px;
            color: #eeeeee;
            line-height: 24px;
        }
        h3{
            margin-top: 30px;
            color: #eeeeee;
        }
    }
}

@media (max-width: 768px) {
    .contactArea .row > div {
        flex: 0 0 100%;
        max-width: 100%;
    }
}