.featured-on-container {
  text-align: center;
  margin: 20px 0;
  h2 {
      margin-bottom: 20px;
      color: #333;
      font-size: 24px;
  }
  .logos {
      display: grid;
      grid-template-columns: 1fr; 
      gap: 10px;
      align-items: center;
      justify-items: center;
      padding: 10px;
      margin: 0 auto;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.3s ease;
        filter: grayscale(100%);
        img {
            max-width: 80%;
            height: auto;
        }        
        &:hover {
            transform: scale(1.1);
            filter: grayscale(0);
        }
      }
      @media (max-width: 767px) {
        a img {
            max-width: 50%; // Limit logo image max-width to 40% on mobile
        }
      }
      @media (min-width: 768px) {
          grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
          gap: 20px;
          padding: 20px;
      }
  }
}
