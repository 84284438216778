.officeServiceArea{
    padding: auto;
    background: #f1f1f1;
    margin-top: -10px;
    margin-bottom: -10px;
    position: relative;
    text-align: center;
    @media (max-width:480px) {
        padding: 20px 0 0 0;
    }
    .brandPromisesHeading {
        padding-top: 20px;
        margin: 0 auto;
        font-size: 28px;
    }
    .serviceItem{
        overflow: visible;
        margin: auto;
        background: #fff;
        @media (max-width:480px) {
            margin: 10px 0 10px 0; 
            margin-left: 60px;   
        }
        .serviceIcon{
            background: #141412;
            flex-grow: 0;
            flex-shrink: 0;
            text-align: center;
            @media (max-width:1200px) {
                height: 90px;
                width: 90px;
            }
            i{
                color: #EEBA2B;
            }
        }
        .serviceText{
            padding: 0px;
            flex-grow: 1;
            text-align: center;
            span{
                color: #333;
                font-size: 16px;
                white-space: nowrap;
            }
            h3{
                font-size: 20px;
                font-weight: bold;
                color: #333;
                text-align: center;
                white-space: nowrap;
                @media (max-width:1200px) {
                    font-size: 22px;
                }                        
            }
        }
    }
}